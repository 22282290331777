<template>
  <v-container>
    <v-col cols="12" sm="12" lg="12">
      <base-material-card color="primary" icon="mdi-account-plus">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="create">
            <v-row>
              <v-col cols="12" sm="4" lg="4" md="4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field v-model="surname" :label="$t('create-manager.manager')" clearable/>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="agencyId" :items="agencies" :label="$t('create-manager.agency')" item-text="agencyName"
                    item-value="agencyId">
                  </v-autocomplete>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4">
                <v-text-field v-model="position" :label="$t('create-manager.position')" clearable/>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" lg="6">
                <validation-provider :rules="{required:true,email:true}" v-slot="{ errors }">
                  <v-text-field
                    v-model="email" :label="$t('create-manager.email')" clearable/>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6" lg="6">
                <v-text-field v-model="mobilePhone" :label="$t('create-manager.mobile')" clearable/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea v-model="comment" class="purple-input" :label="$t('create-manager.comments')"
                            clearable counter rows="2"/>
              </v-col>
            </v-row>
            <v-btn :disabled="invalid" block color="primary" @click="createManager">
              {{$t('buttons.create-manager')}}
            </v-btn>
          </v-form>
        </validation-observer>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import {email} from 'vee-validate/dist/rules';
  import {useAgencies} from "../../stores/agenciesStore";
  import {useManagers} from "../../stores/managersStore";
  import {mapState} from "pinia";

  const managerStore = useManagers()
  const agencyStore = useAgencies()

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  extend('email', {
    ...email,
    message: 'Enter a valid e-mail'
  });

  let moment = require('moment');

  export default {
    name: "Create Manager",
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        surname: '',
        agencyId: '',
        email: '',
        mobilePhone: '',
        comment: '',
        position: '',
        loading: false
      }
    },
    methods: {
      createManager() {
        this.loading = true;
        let action = 'create'
        let objectType = 'Manager'
        let params = new URLSearchParams();
        params.append('surname', this.surname);
        params.append('agencyId', this.agencyId);
        params.append('email', this.email);
        params.append('position', this.position);
        params.append('mobilePhone', this.mobilePhone);
        params.append('comment', this.comment);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createManager/`, params)
          .then(response => {
            managerStore.addManager(response.data)
            this.userNotification(response.data, action, objectType);
            this.loading = false;
            this.$router.push({path: '/manager/' + response.data.managerId})
          })
      },
      updateNeededCheck() {
        if (!this.agencies.length) {
          agencyStore.getAgencyList()
        }
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      ...mapState(useManagers, ['managers']),
    },
    created() {
      this.updateNeededCheck()
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
