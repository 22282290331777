var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('base-material-card',{attrs:{"color":"primary","icon":"mdi-account-plus"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.create($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"4","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-manager.manager'),"clearable":""},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.agencies,"label":_vm.$t('create-manager.agency'),"item-text":"agencyName","item-value":"agencyId"},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('create-manager.position'),"clearable":""},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":{required:true,email:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('create-manager.email'),"clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('create-manager.mobile'),"clearable":""},model:{value:(_vm.mobilePhone),callback:function ($$v) {_vm.mobilePhone=$$v},expression:"mobilePhone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"purple-input",attrs:{"label":_vm.$t('create-manager.comments'),"clearable":"","counter":"","rows":"2"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-btn',{attrs:{"disabled":invalid,"block":"","color":"primary"},on:{"click":_vm.createManager}},[_vm._v(" "+_vm._s(_vm.$t('buttons.create-manager'))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }